import React, { useEffect, useState } from 'react';
import { Elements, useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { useNavigate, useLocation } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import { CognitoIdentityProviderClient, AdminInitiateAuthCommand, InitiateAuthCommand } from "@aws-sdk/client-cognito-identity-provider";
import ReactGA from 'react-ga4';



const getStripeKey = () => {
    const isLocalhost = window.location.href.includes('http://localhost:3000/');
    const isInjsur = window.location.href.includes('https://www.injsur.ai/');

    if (isLocalhost) {
        return 'pk_test_51PpgfUGrzdk3qn40VjV9BuM4On87glDerD3QFtirYH58uRk7yF59RvwpJQDP54VMLfJbldi8NbXl7j3f5ezCXkan00ZAawSzUZ';
    } else if (isInjsur) {
        return 'pk_live_51PpgfUGrzdk3qn40Ed5iDLfsdaxCtimHkhYUaub4kEhCvcgGRNychgbZwqbhZjvWorbp8hEZ8Bw10NMGFfxsHJuA00bz2sTt5w';
    }
    throw new Error('Invalid environment URL');
};

const stripePromise = loadStripe(getStripeKey());

const PaymentForm = ({ navigateToVerify, email, attributes, password }) => {
    const location = useLocation();
    const [selectedSubscriptionType, setSelectedSubscriptionType] = useState('monthly');
    const [isLoading, setIsLoading] = useState(false);
    const stripe = useStripe();
    const elements = useElements();
    const navigate = useNavigate();

    const currentDate = new Date().toISOString();  // Get current date in ISO format

    attributes = {
        ...attributes,
        'custom:created_date': currentDate,  // Set created_date as the current time
        'custom:updated_date': currentDate   // Set updated_date as the current time
    };

    const SUBSCRIPTION_COSTS = {
        monthly: 14.99,
        yearly: 79.99,
    };
    const STRIPE_PRICE_IDS = {
        monthly: 'price_1QGXevGrzdk3qn40zdugZfNZ', //'price_1PseYMGrzdk3qn404whu4xFs', this is 19.99 the id ending in BiA1 is 14.99
        yearly: 'price_1PseY7Grzdk3qn408rkfhqMm',
    };
    const priceId = STRIPE_PRICE_IDS[selectedSubscriptionType];
    console.log("payment attributes", attributes)
    const subscriptionCost = SUBSCRIPTION_COSTS[selectedSubscriptionType];

    const STRIPE_PERCENTAGE_FEE = 0.029;
    const STRIPE_FLAT_FEE = 0.30;
    const stripeFee = (subscriptionCost * STRIPE_PERCENTAGE_FEE) + STRIPE_FLAT_FEE;
    const finalTotalCost = subscriptionCost

    const handlePayment = async (event) => {
        event.preventDefault();
        setIsLoading(true);
    
        if (!stripe || !elements) {
            setIsLoading(false);
            return;
        }
    
        try {
            // Track the event for Google Analytics
            if (typeof ReactGA !== 'undefined') {
                ReactGA.event({
                    category: 'Payment',
                    action: 'Submit Payment',
                    label: selectedSubscriptionType,
                    value: finalTotalCost,
                });
            }
            console.log('Google Analytics Event Sent:', {
                category: 'Payment',
                action: 'Submit Payment',
                label: selectedSubscriptionType,
                value: finalTotalCost,
            });
    
            // Track the event for Facebook Meta Pixel
            if (typeof window.fbq !== 'undefined') {
                window.fbq('track', 'Purchase', {
                    value: finalTotalCost,
                    currency: 'USD',
                    subscriptionType: selectedSubscriptionType,
                });
                console.log('Facebook Pixel Event Sent:', {
                    value: finalTotalCost,
                    currency: 'USD',
                    subscriptionType: selectedSubscriptionType,
                });
            }
    
            // Create customer
            const customerResponse = await fetch(
                'https://bb2b9p5bpk.execute-api.us-west-1.amazonaws.com/prod/CreateCustomer',
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ email: email }),
                }
            );
            const customer = await customerResponse.json();
    
            // Create payment method
            const cardElement = elements.getElement(CardElement);
            const { paymentMethod, error } = await stripe.createPaymentMethod({
                type: 'card',
                card: cardElement,
                billing_details: { email: email },
            });
    
            if (error) {
                console.log(error);
                setIsLoading(false);
                return;
            }
    
            // Attach payment method
            await fetch(
                'https://bb2b9p5bpk.execute-api.us-west-1.amazonaws.com/prod/AttachPaymentMethod',
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        customer: customer.customerId,
                        paymentMethod: paymentMethod.id,
                    }),
                }
            );
                // Create subscription
            // const subscriptionResponse = await fetch('https://bb2b9p5bpk.execute-api.us-west-1.amazonaws.com/prod/CreateSubscription', { use this for monthly and season pass for one off expiring subscription

            //     method: 'POST',
            //     headers: {
            //         'Content-Type': 'application/json',
            //     },
            //     body: JSON.stringify({
            //         customer: customer.customerId,
            //         subscriptionType: selectedSubscriptionType // send selectedSubscriptionType instead of priceId
            //     })
            // });
            // Create subscription
            const subscriptionResponse = await fetch(
                'https://bb2b9p5bpk.execute-api.us-west-1.amazonaws.com/prod/createSeasonPassSubscription',
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        customer: customer.customerId,
                        subscriptionType: selectedSubscriptionType,
                        paymentMethodId: paymentMethod.id,
                    }),
                }
            );
    
            const subscription = await subscriptionResponse.json();
    
            if (!subscriptionResponse.ok) {
                throw new Error('Failed to create subscription');
            }
    
            // Update attributes with customer and subscription IDs before signing up
            const updatedAttributes = {
                ...attributes,
                'custom:customer_id': customer.customerId,
                'custom:subscription_id': subscription.subscriptionId,
            };
    
            // Sign up user with updated attributes including subscription ID
            await Auth.signUp({
                username: email,
                password: password,
                attributes: updatedAttributes,
            });
    
            // Trigger tracking pixel for ShareASale
            const trackingPixel = document.createElement('img');
            trackingPixel.src = `https://www.shareasale.com/sale.cfm?tracking=${customer.customerId}&amount=${finalTotalCost}&merchantID=161860&transtype=sale`;
            trackingPixel.width = 1;
            trackingPixel.height = 1;
            document.body.appendChild(trackingPixel);
    
            // Navigate to verify screen
            await navigateToVerify(email, password);
        } catch (err) {
            console.error(err);
        } finally {
            setIsLoading(false);
        }
    };
    



    return (
        <form onSubmit={handlePayment}>

            <div className="mb-4">
                <h2 className="text-xl font-bold">Enter Payment Details</h2>

                <div className="mb-4">
                    <label className="block text-sm font-medium text-gray-700">Choose Subscription Type:</label>
                    <div className="mt-1 flex gap-4">
                        {/* Monthly Subscription Box */}
                        <div
                            className={`cursor-pointer border p-4 rounded-md ${selectedSubscriptionType === 'monthly' ? 'border-indigo-600 bg-indigo-50' : 'border-gray-300'}`}
                            onClick={() => setSelectedSubscriptionType('monthly')}
                        >
                            <input
                                type="radio"
                                name="subscriptionType"
                                value="monthly"
                                checked={selectedSubscriptionType === 'monthly'}
                                onChange={() => setSelectedSubscriptionType('monthly')}
                                className="form-radio text-indigo-600 hidden"
                            />
                            <div className="flex items-center">
                                {/* <span className="ml-2 text-lg font-bold">Injsur Monthly - $14.99</span> */}
                                <span className="ml-2 text-lg font-bold">Injsur Season Pass - $14.99</span>
                            </div>
                        </div>

                        {/* Yearly Subscription Box */}
                        {/* <div 
            className={`cursor-pointer border p-4 rounded-md ${selectedSubscriptionType === 'yearly' ? 'border-indigo-600 bg-indigo-50' : 'border-gray-300'}`}
            onClick={() => setSelectedSubscriptionType('yearly')}
        >
            <input
                type="radio"
                name="subscriptionType"
                value="yearly"
                checked={selectedSubscriptionType === 'yearly'}
                onChange={() => setSelectedSubscriptionType('yearly')}
                className="form-radio text-indigo-600 hidden"
            />
            <div className="flex items-center">
                <span className="ml-2 text-lg font-bold" >Injsur Yearly - $79.99</span>
            </div>
        </div> */}
                    </div>
                </div>


                {/* <p>Subscription Cost: ${SUBSCRIPTION_COSTS[selectedSubscriptionType].toFixed(2)} (Auto-Renews {selectedSubscriptionType.charAt(0).toUpperCase() + selectedSubscriptionType.slice(1)})</p> */}
                <p>
                    Subscription Cost: ${SUBSCRIPTION_COSTS[selectedSubscriptionType].toFixed(2)} (Expires on February 10, 2025)
                </p>

                <hr className="my-2" style={{ borderTop: "1px solid black" }} />
                <p>Total Cost Due Now: ${(subscriptionCost).toFixed(2)}</p>
            </div>

            <div className="payment-form-container" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', gap: '20px' }}>

                {/* Card Details Section */}
                <div className="card-details" style={{ flex: '1' }}>
                    <h2 className="text-xl font-bold">Card Details</h2>
                    <div className="mb-4">
                        {/* Card input fields */}
                        <div className="mb-4">
                            <label className="block text-sm font-medium text-gray-700">
                                Name on Card:
                            </label>
                            <input
                                type="text"
                                name="nameOnCard"
                                className="mt-1 p-2 border rounded-md w-full"
                                required
                            />
                        </div>
                        <div className="mb-4">
                            <label className="block text-sm font-medium text-gray-700">
                                Billing Address:
                            </label>
                            <input
                                type="text"
                                name="streetAddress"
                                className="mt-1 p-2 border rounded-md w-full"
                                required
                            />
                        </div>
                        <div className="mb-4">
                            <label className="block text-sm font-medium text-gray-700">
                                Zip Code:
                            </label>
                            <input
                                type="text"
                                name="zipCode"
                                className="mt-1 p-2 border rounded-md w-full"
                                required
                            />
                        </div>
                        <div className="mb-4">
                            <label className="block text-sm font-medium text-gray-700">
                                Card Details:
                            </label>
                            <div className="mt-1 p-2 border rounded-md">
                                <CardElement options={{ hidePostalCode: true }} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="flex justify-between mt-4">
                <button
                    type="button"
                    onClick={() => navigate('/sign-up-terms-of-service', {
                        state: {
                            email,
                            password,
                            attributes,
                            accountType: location.state.accountType,
                            returnUrl: location.state.returnUrl,
                            subscriptionType: location.state.subscriptionType
                        }
                    })}
                    className="bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-700"
                >
                    Back
                </button>

                <button
                    type="submit"
                    disabled={isLoading}
                    className={`bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600 ${isLoading ? 'opacity-70 cursor-not-allowed' : ''}`}
                >
                    {isLoading ? (
                        <>
                            <svg aria-hidden="true" role="status" className="inline w-4 h-4 mr-3 text-white animate-spin" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="#E5E7EB" />
                                <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentColor" />
                            </svg>
                            Processing...
                        </>
                    ) : (
                        "Submit Payment"
                    )}
                </button>
            </div>
        </form>
    );
};

const PaymentHandler = ({ email, password, attributes }) => {
    const navigate = useNavigate();

    const navigateToVerify = async (email) => {
        try {
            // Initialize the Cognito client
            const client = new CognitoIdentityProviderClient({ region: "us-west-1" });

            // Prepare parameters for InitiateAuth with CUSTOM_AUTH, omitting PASSWORD
            const params = {
                AuthFlow: "CUSTOM_AUTH",
                ClientId: "18a9oeq65hd4t11jrd54fb5ln7", // Replace with your actual Cognito ClientId
                AuthParameters: {
                    USERNAME: email,
                },
            };

            // Execute the command
            const command = new InitiateAuthCommand(params);
            const response = await client.send(command);

            // Ensure successful initiation
            if (response.AuthenticationResult || response.ChallengeName === "CUSTOM_CHALLENGE") {
                // Navigate to the verification screen with the email
                navigate(`/verify/${email}`, {
                    state: { email }, // Pass the email for further steps
                });
            } else {
                console.error("Unexpected response during custom auth initiation:", response);
            }
        } catch (error) {
            console.error("Error initiating custom auth:", error);
        }
    };

    return (
        <React.Fragment>
            <div className="bg-gray-200 min-h-screen">
                <p className="flex justify-center pt-6 mb-4 text-2xl text-black transition-all ease-in duration-125">
                    Payment
                </p>
                <div className="mx-auto max-w-2xl p-6 bg-white shadow-md rounded-lg relative">
                    <Elements stripe={stripePromise}>
                        <PaymentForm navigateToVerify={navigateToVerify} email={email} password={password} attributes={attributes} />
                    </Elements>
                </div>
            </div>
        </React.Fragment>
    );
};

export default PaymentHandler;

