import React, { useState, useEffect } from "react";
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { UseAuth } from './AuthContext.js';
import { Auth } from 'aws-amplify';
import { Amplify } from 'aws-amplify';
import awsmobile from '../aws-exports';
import PhoneInput from 'react-phone-number-input/input';
import 'react-phone-number-input/style.css'; // Import PhoneInput styles

import logo from '../images/injsurlogo2.png'; // Adjust the path based on your project structure

Amplify.configure(awsmobile);

const useQuery = () => {
    return new URLSearchParams(useLocation().search);
};

const Login = () => {
    const { setToken } = UseAuth();
    const navigate = useNavigate();
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [phoneNumber, setPhoneNumber] = useState(''); // Add phoneNumber state
    const [errorMessage, setErrorMessage] = useState('');
    const [isForceChangePassword, setIsForceChangePassword] = useState(false);
    const [cognitoUser, setCognitoUser] = useState(null);
    const query = useQuery();
    const returnUrl = query.get("returnUrl") || '/home'; // Fallback to a default route
    const [showPassword, setShowPassword] = useState(false);
    const [socialError, setSocialError] = useState('');

    useEffect(() => {
        const checkUser = async () => {
            try {
                const user = await Auth.currentAuthenticatedUser();
                const session = await Auth.currentSession();
                setToken(session.getIdToken().getJwtToken());

                // User is already logged in, redirect to a default route
                navigate('/home');
            } catch (error) {
                // User is not logged in, stay on the login page
                console.log('No authenticated user found.', error);
            }
        };

        checkUser();
    }, [navigate, setToken]);

    const handleLogin = async (event) => {
        event.preventDefault();

        // Trim the username to ensure no leading/trailing whitespace
        const trimmedUsername = username.trim();

        if (!trimmedUsername) {
            setErrorMessage('Please enter your username.');
            return;
        }
        if (!password) {
            setErrorMessage('Password cannot be empty.');
            return;
        }

        if (isForceChangePassword) {
            try {
                // Include the phone_number attribute if it's required
                const userAttributes = phoneNumber ? { phone_number: phoneNumber } : {};
                await Auth.completeNewPassword(cognitoUser, newPassword, userAttributes);

                // Automatically log the user in
                const user = await Auth.signIn(trimmedUsername, newPassword);
                const session = await Auth.currentSession();
                setToken(session.getIdToken().getJwtToken());

                // Redirect the user to the specified returnUrl or the default route
                navigate(returnUrl);
            } catch (error) {
                console.error('Error completing new password:', error);
                setErrorMessage(error.message || 'An error occurred during password reset.');
            }
            return;
        }

        try {
            // Use the trimmed username for the sign-in process
            const user = await Auth.signIn(trimmedUsername, password);

            if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
                setIsForceChangePassword(true);
                setCognitoUser(user);
                setErrorMessage('Please set a new password.');
            } else {
                const session = await Auth.currentSession();
                const idToken = session.getIdToken().getJwtToken();
                setToken(idToken);

                // Redirect the user to the specified returnUrl or the default route
                navigate(returnUrl);
            }
        } catch (error) {
            if (error.code === 'UserNotConfirmedException') {
                // Redirect to the verification component, using trimmedUsername
                navigate(`/verify/${trimmedUsername}`, { state: { password } });
            } else {
                console.error('Authentication error:', error);
                setErrorMessage(error.message || 'An error occurred during sign in.');
            }
        }
    };

    const signUpLink = `/sign-up${query.has("returnUrl") ? `?returnUrl=${encodeURIComponent(returnUrl)}` : ''}`;

    return (
        <div className="flex min-h-screen justify-center items-center bg-zinc-950 flex-col">
            <Link to="/">
                <div className="relative">
                    <img src={logo} alt="Logo" className="h-32 md:h-32" />
                    <span className="absolute bottom-0 right-0 mb-4 text-[#fbf4e4] text-sm md:text-base font-bold">™</span>
                </div>
            </Link>


            <div className="bg-zinc-800 p-6 rounded-lg shadow-md w-full max-w-md">
                <h2 className="text-2xl mb-1 text-center" style={{ color: '#fbf4e4' }}>Injsur Sign In</h2>
                {errorMessage && <div className="text-red-500 mb-4 text-center">{errorMessage}</div>}

                <form onSubmit={handleLogin}>
                    <div className="mb-4">
                        <label className="block text-gray-700 text-sm font-bold mb-2" style={{ color: '#fbf4e4' }}>Email</label>
                        <input
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 text-lg leading-tight focus:outline-none focus:shadow-outline"
                            type="text"
                            placeholder="Email"
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                        />
                    </div>
                    <div className="mb-4">
                        <label className="block text-gray-700 text-sm font-bold mb-2" style={{ color: '#fbf4e4' }}>Password</label>
                        <input
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 text-lg leading-tight focus:outline-none focus:shadow-outline"
                            type={showPassword ? "text" : "password"} // Conditionally render the input type
                            placeholder="Password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />

                    </div>
                    <div className="mb-4">
                        <label className="inline-flex items-center text-gray-700 text-sm" style={{ color: '#fbf4e4' }}>
                            <input
                                type="checkbox"
                                className="mr-2 leading-tight h-4 w-4" // Adjust checkbox size
                                checked={showPassword}
                                onChange={() => setShowPassword(!showPassword)}
                            />
                            <span className="text-md">Show Password</span> {/* Increase font size for label */}
                        </label>
                    </div>



                    {isForceChangePassword && (
                        <>
                            <div className="mb-4">
                                <label className="block text-gray-700 text-sm font-bold mb-2">New Password</label>
                                <input
                                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                    type="password"
                                    placeholder="New password"
                                    value={newPassword}
                                    onChange={(e) => setNewPassword(e.target.value)}
                                />
                            </div>
                            <div className="mb-4">
                                <label className="block text-gray-700 text-sm font-bold mb-2">Phone Number</label>
                                <PhoneInput
                                    placeholder="Phone Number"
                                    value={phoneNumber}
                                    onChange={setPhoneNumber}
                                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                    defaultCountry="US"
                                    required
                                />
                            </div>
                        </>
                    )}

                    <button
                        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-3 px-4 rounded focus:outline-none focus:shadow-outline w-full"
                        type="submit"
                    >
                        Log In
                    </button>

                    <p className="mt-2 text-center">
                        <Link to="/reset-password" className="text-blue-500">Forgot password?</Link>
                    </p>
                </form>

                <div className="mt-4 flex flex-col items-center space-y-4">
                    {/* Google Sign-In Button */}
                    {/* Google Sign-In Button */}
                    <button
                        onClick={async () => {
                            try {
                                setSocialError('');
                                await Auth.federatedSignIn({ provider: 'Google' });
                            } catch (error) {
                                console.error('Google Sign-In Error:', error);
                                // If the error indicates a duplicate user, show a helpful message
                                if (error.message && error.message.includes('email already exists')) {
                                    setSocialError('This email is already registered. Please sign in with your email and password.');
                                } else {
                                    setSocialError('An error occurred during Google sign-in. Please try again.');
                                }
                            }
                        }}
                        className="flex items-center justify-center bg-white border border-gray-300 hover:bg-gray-100 text-gray-700 font-medium py-2 px-4 rounded shadow-md w-full"
                    >
                        <img
                            src="https://developers.google.com/identity/images/g-logo.png"
                            alt="Google logo"
                            className="h-5 w-5 mr-2"
                        />
                        Sign in with Google
                    </button>

                    {/* Facebook Sign-In Button */}
                    <button
                        onClick={async () => {
                            try {
                                setSocialError('');
                                await Auth.federatedSignIn({ provider: 'Facebook' });
                            } catch (error) {
                                console.error('Facebook Sign-In Error:', error);
                                // If the error indicates a duplicate user, show a helpful message
                                if (error.message && error.message.includes('email already exists')) {
                                    setSocialError('This email is already registered. Please sign in with your email and password.');
                                } else {
                                    setSocialError('An error occurred during Facebook sign-in. Please try again.');
                                }
                            }
                        }}
                        className="flex items-center justify-center bg-blue-600 hover:bg-blue-700 text-white font-medium py-2 px-4 rounded shadow-md w-full"
                    >
                        <img
                            src="https://upload.wikimedia.org/wikipedia/commons/6/6c/Facebook_Logo_2023.png"
                            alt="Facebook logo"
                            className="h-5 w-5 mr-2"
                        />
                        Sign in with Facebook
                    </button>
                    {socialError && (
                        <div className="text-red-500 mt-2 text-center">
                            {socialError}
                        </div>
                    )}

                </div>








                <div className="mt-8 text-center">
                    <Link
                        to={signUpLink}
                        className="bg-[#d8bf82] text-black font-bold py-2.5 px-6 rounded-lg inline-block hover:bg-[#a78c5d] transition-colors"
                    >
                        Create New Account
                    </Link>
                </div>

            </div>
            <p className="mt-6 mb-4 text-gray-300 text-center">
                Having trouble? Contact support at support@injsur.ai
            </p>
        </div>
    );
};

export default Login;
