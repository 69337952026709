// PaymentHandlerFree.js

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import { CognitoIdentityProviderClient, InitiateAuthCommand } from '@aws-sdk/client-cognito-identity-provider';
import ReactGA from 'react-ga4';
import PhoneInput from 'react-phone-number-input/input';
import axios from 'axios';
import logo from '../images/injsurlogoGreen.png';

const SignUpFree = () => {
    const navigate = useNavigate();
    const [formValues, setFormValues] = useState({
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        password: '',
        confirmPassword: '',
        city: '',
        state: '',
        zip: '',
        address: '',
    });
    const [showFullForm, setShowFullForm] = useState(false);

    const [showPassword, setShowPassword] = useState(false);
    const [passwordRequirements, setPasswordRequirements] = useState({
        minLength: false,
        uppercase: false,
        lowercase: false,
        number: false,
        specialChar: false,
    });
    const [formError, setFormError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const currentDate = new Date().toISOString();

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormValues((prevValues) => ({
            ...prevValues,
            [name]: value,
        }));
    };

    const handlePhoneChange = (value) => {
        setFormValues((prevValues) => ({
            ...prevValues,
            phone: value,
        }));
    };

    const handlePasswordChange = (event) => {
        const password = event.target.value;
        setFormValues((prevValues) => ({
            ...prevValues,
            password,
        }));
        const requirements = {
            minLength: password.length >= 8,
            uppercase: /[A-Z]/.test(password),
            lowercase: /[a-z]/.test(password),
            number: /[0-9]/.test(password),
            specialChar: /[^A-Za-z0-9]/.test(password),
        };
        setPasswordRequirements(requirements);
    };

    const checkUserExists = async (email) => {
        try {
            const response = await axios.post(
                'https://bb2b9p5bpk.execute-api.us-west-1.amazonaws.com/prod/checkUser',
                { email }
            );
            return response.data.exists;
        } catch (error) {
            return false;
        }
    };

    const navigateToVerify = async (email) => {
        try {
            const client = new CognitoIdentityProviderClient({ region: 'us-west-1' });
            const params = {
                AuthFlow: 'CUSTOM_AUTH',
                ClientId: '18a9oeq65hd4t11jrd54fb5ln7',
                AuthParameters: {
                    USERNAME: email, // Ensure the email is correctly formatted
                },
            };
            const command = new InitiateAuthCommand(params);
            const response = await client.send(command);
    
            console.log("InitiateAuth Response:", JSON.stringify(response, null, 2));
    
            if (response.AuthenticationResult || response.ChallengeName === 'CUSTOM_CHALLENGE') {
                console.log("Redirecting to verification page...");
                navigate(`/verify/${email}`, {
                    state: { email },
                });
            } else {
                console.error("Unexpected response from InitiateAuth:", response);
                throw new Error("Unable to initiate custom auth flow.");
            }
        } catch (error) {
            console.error("Error during navigateToVerify:", error);
        }
    };
    

    const handleSubmit = async (event) => {
        event.preventDefault();
        setIsLoading(true);
        setFormError(null);
        if (formValues.password !== formValues.confirmPassword) {
            setFormError('Passwords do not match');
            setIsLoading(false);
            return;
        }
        const isPasswordValid = Object.values(passwordRequirements).every(Boolean);
        if (!isPasswordValid) {
            setFormError('Password does not meet criteria');
            setIsLoading(false);
            return;
        }
        const userExists = await checkUserExists(formValues.email);
        if (userExists) {
            setFormError('An account with the given email already exists.');
            setIsLoading(false);
            return;
        }
        const {
            firstName,
            lastName,
            email,
            phone,
            password,
            city,
            state,
            zip,
            address,
        } = formValues;
        let attributes = {
            'custom:first_name': firstName,
            'custom:last_name': lastName,
            email: email,
            phone_number: phone,
            'custom:city': city,
            'custom:state': state,
            'custom:zip': zip,
            'custom:address': address,
            'custom:created_date': currentDate,
            'custom:updated_date': currentDate,
        };
        try {
            if (typeof ReactGA !== 'undefined') {
                ReactGA.event({
                    category: 'Signup',
                    action: 'Submit Signup',
                    label: 'Free Signup',
                });
            }
            await Auth.signUp({
                username: email,
                password: password,
                attributes: attributes,
            });
            await navigateToVerify(email);
        } catch (err) {
            setFormError(err.message || 'An error occurred during signup.');
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className="bg-gray-200 min-h-screen py-10">
            <div className="flex justify-center items-center">
                <img src={logo} alt="Logo" className="h-36 md:h-36" />
                <span className="absolute bottom-0 right-0 mb-4 text-[#fbf4e4] text-sm md:text-base font-bold">™</span>
            </div>
            <div className="mx-auto max-w-xl px-12 py-6 bg-white shadow-md rounded-lg">
                {!showFullForm && (
                    <div className="flex flex-col items-center space-y-4 mb-6">
                        <h2 className="text-xl font-bold">Create an Account</h2>
                        <button
                            onClick={async () => {
                                try {
                                    await Auth.federatedSignIn({ provider: 'Google' });
                                } catch (error) {
                                    console.error('Google Sign-In Error:', error);
                                }
                            }}
                            className="flex items-center justify-center bg-white border border-gray-300 hover:bg-gray-100 text-gray-700 font-medium py-2 px-4 rounded shadow-md w-full"
                        >
                            <img
                                src="https://developers.google.com/identity/images/g-logo.png"
                                alt="Google logo"
                                className="h-5 w-5 mr-2"
                            />
                            Continue with Google
                        </button>
                        <button
                            onClick={async () => {
                                try {
                                    await Auth.federatedSignIn({ provider: 'Facebook' });
                                } catch (error) {
                                    console.error('Facebook Sign-In Error:', error);
                                }
                            }}
                            className="flex items-center justify-center bg-blue-600 hover:bg-blue-700 text-white font-medium py-2 px-4 rounded shadow-md w-full"
                        >
                            <img
                                src="https://upload.wikimedia.org/wikipedia/commons/6/6c/Facebook_Logo_2023.png"
                                alt="Facebook logo"
                                className="h-5 w-5 mr-2"
                            />
                            Continue with Facebook
                        </button>
                        <button
                            onClick={() => setShowFullForm(true)}
                            className="bg-white border border-black text-black py-2 px-4 rounded w-full hover:bg-gray-100"
                        >
                            Continue with Email
                        </button>

                        <p className="mt-6 text-gray-600 text-center">
                            Already have an account? <a href='/login' className="text-blue-500">Sign in</a>
                        </p>
                        <p className="text-sm text-gray-600 mb-4">
                            By creating an account, you agree to Injsur.AI's{' '}
                            <a href="/terms-of-use" className="text-blue-600 underline">Terms & Conditions</a>{' '}
                            and{' '}
                            <a href="/privacy-policy" className="text-blue-600 underline">Privacy Policy</a>.
                        </p>

                    </div>
                )}

                {showFullForm && (
                    <form onSubmit={handleSubmit}>
                        <h2 className="text-xl font-bold mb-4">Create New Account</h2>
                        <div className="flex justify-between space-x-2">
                            <input
                                type="text"
                                name="firstName"
                                placeholder="First Name"
                                value={formValues.firstName}
                                onChange={handleInputChange}
                                className="border p-2 mb-2 w-1/2 rounded"
                                required
                            />
                            <input
                                type="text"
                                name="lastName"
                                placeholder="Last Name"
                                value={formValues.lastName}
                                onChange={handleInputChange}
                                className="border p-2 mb-2 w-1/2 rounded"
                                required
                            />
                        </div>
                        <div className="flex justify-between space-x-2">
                            <input
                                type="text"
                                name="city"
                                placeholder="City"
                                value={formValues.city}
                                onChange={handleInputChange}
                                className="border p-2 mb-2 w-1/3 rounded"
                                required
                            />
                            <select
                                name="state"
                                value={formValues.state}
                                onChange={handleInputChange}
                                className="border p-2 mb-2 w-1/3 rounded text-gray-800"
                                required
                            >
                                <option value="" disabled>Select State</option>
                                <option value="Alabama">Alabama</option>
                                <option value="Alaska">Alaska</option>
                                <option value="Arizona">Arizona</option>
                                <option value="Arkansas">Arkansas</option>
                                <option value="California">California</option>
                                <option value="Colorado">Colorado</option>
                                <option value="Connecticut">Connecticut</option>
                                <option value="Delaware">Delaware</option>
                                <option value="Florida">Florida</option>
                                <option value="Georgia">Georgia</option>
                                <option value="Hawaii">Hawaii</option>
                                <option value="Idaho">Idaho</option>
                                <option value="Illinois">Illinois</option>
                                <option value="Indiana">Indiana</option>
                                <option value="Iowa">Iowa</option>
                                <option value="Kansas">Kansas</option>
                                <option value="Kentucky">Kentucky</option>
                                <option value="Louisiana">Louisiana</option>
                                <option value="Maine">Maine</option>
                                <option value="Maryland">Maryland</option>
                                <option value="Massachusetts">Massachusetts</option>
                                <option value="Michigan">Michigan</option>
                                <option value="Minnesota">Minnesota</option>
                                <option value="Mississippi">Mississippi</option>
                                <option value="Missouri">Missouri</option>
                                <option value="Montana">Montana</option>
                                <option value="Nebraska">Nebraska</option>
                                <option value="Nevada">Nevada</option>
                                <option value="New Hampshire">New Hampshire</option>
                                <option value="New Jersey">New Jersey</option>
                                <option value="New Mexico">New Mexico</option>
                                <option value="New York">New York</option>
                                <option value="North Carolina">North Carolina</option>
                                <option value="North Dakota">North Dakota</option>
                                <option value="Ohio">Ohio</option>
                                <option value="Oklahoma">Oklahoma</option>
                                <option value="Oregon">Oregon</option>
                                <option value="Pennsylvania">Pennsylvania</option>
                                <option value="Rhode Island">Rhode Island</option>
                                <option value="South Carolina">South Carolina</option>
                                <option value="South Dakota">South Dakota</option>
                                <option value="Tennessee">Tennessee</option>
                                <option value="Texas">Texas</option>
                                <option value="Utah">Utah</option>
                                <option value="Vermont">Vermont</option>
                                <option value="Virginia">Virginia</option>
                                <option value="Washington">Washington</option>
                                <option value="West Virginia">West Virginia</option>
                                <option value="Wisconsin">Wisconsin</option>
                                <option value="Wyoming">Wyoming</option>
                            </select>
                            <input
                                type="text"
                                name="zip"
                                placeholder="Zip Code"
                                value={formValues.zip}
                                onChange={handleInputChange}
                                className="border p-2 mb-2 w-1/3 rounded"
                                required
                            />
                        </div>
                        <input
                            type="text"
                            name="address"
                            placeholder="Street Address"
                            value={formValues.address}
                            onChange={handleInputChange}
                            className="border p-2 mb-2 w-full rounded"
                            required
                        />
                        <input
                            type="email"
                            name="email"
                            placeholder="Email"
                            value={formValues.email}
                            onChange={handleInputChange}
                            className="border p-2 mb-2 w-full rounded"
                            required
                        />
                        <PhoneInput
                            name="phone"
                            placeholder="Phone Number"
                            value={formValues.phone}
                            onChange={handlePhoneChange}
                            className="border p-2 mb-2 w-full rounded"
                            defaultCountry="US"
                            required
                        />
                        <input
                            type={showPassword ? 'text' : 'password'}
                            name="password"
                            placeholder="Password"
                            value={formValues.password}
                            onChange={handlePasswordChange}
                            className="border p-2 mb-2 w-full rounded"
                            required
                        />
                        <div className="mb-4">
                            <label className="inline-flex items-center text-gray-700 text-sm">
                                <input
                                    type="checkbox"
                                    className="mr-2 leading-tight h-4 w-4"
                                    checked={showPassword}
                                    onChange={() => setShowPassword(!showPassword)}
                                />
                                <span className="text-md">Show Password</span>
                            </label>
                        </div>
                        <p
                            className={
                                passwordRequirements.minLength &&
                                    passwordRequirements.uppercase &&
                                    passwordRequirements.lowercase &&
                                    passwordRequirements.number &&
                                    passwordRequirements.specialChar
                                    ? 'text-green-600'
                                    : 'text-red-600'
                            }
                        >
                            • At least 8 characters
                        </p>
                        <p className={passwordRequirements.uppercase ? 'text-green-600' : 'text-red-600'}>
                            • At least one uppercase letter
                        </p>
                        <p className={passwordRequirements.lowercase ? 'text-green-600' : 'text-red-600'}>
                            • At least one lowercase letter
                        </p>
                        <p className={passwordRequirements.number ? 'text-green-600' : 'text-red-600'}>
                            • At least one number
                        </p>
                        <p className={passwordRequirements.specialChar ? 'text-green-600' : 'text-red-600'}>
                            • At least one special character
                        </p>
                        <input
                            type={showPassword ? 'text' : 'password'}
                            name="confirmPassword"
                            placeholder="Confirm Password"
                            value={formValues.confirmPassword}
                            onChange={handleInputChange}
                            className="border p-2 my-2 w-full rounded"
                            required
                        />
                        {formError && <p className="text-red-600">{formError}</p>}
                        <p className="text-sm text-gray-600 mb-4">
                            By clicking Submit, you agree to the Injsur.AI{' '}
                            <a
                                href="https://www.injsur.ai/terms-of-use"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="text-blue-600 underline"
                            >
                                Terms of Use
                            </a>{' '}
                            and{' '}
                            <a
                                href="https://www.injsur.ai/privacy-policy"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="text-blue-600 underline"
                            >
                                Privacy Policy
                            </a>.
                        </p>
                        <button
                            type="submit"
                            disabled={isLoading}
                            className={`bg-blue-500 text-white py-2 px-4 rounded w-full transition-colors duration-300 hover:bg-blue-600 ${isLoading ? 'opacity-70 cursor-not-allowed' : ''}`}
                        >
                            {isLoading ? 'Processing...' : 'Submit'}
                        </button>
                        <p className="mt-6 text-gray-600 text-center">
                            Already have an account? <a href='/login' className="text-blue-500">Sign in</a>
                        </p>
                    </form>
                )}
            </div>
        </div>
    );

};

export default SignUpFree;
