import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import img1 from '../images/injsur2.jpeg'; // Adjust the path based on your project structure
import { Auth } from 'aws-amplify';
import UserHeader from './UserHeader'; // Make sure the path is correct based on your project structure

const UserHomePage = () => {
  const [userName, setUserName] = useState('');
  const location = useLocation();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const authCode = queryParams.get('code');
    const authState = queryParams.get('state');

    if (authCode) {
      // Handle the `code` if needed (e.g., exchange it for tokens)
      console.log('Authorization Code:', authCode);
      console.log('State:', authState);

      // Clean up the URL by removing query parameters
      const newUrl = location.pathname; // "/home"
      window.history.replaceState({}, document.title, newUrl);
    }

    const fetchUserDetails = async () => {
      try {
        const user = await Auth.currentAuthenticatedUser();
        const userAttributes = user.attributes;
        setUserName(userAttributes['given_name']); // Assuming 'given_name' attribute is set in Cognito
      } catch (error) {
        console.error('Error fetching user details:', error);
      }
    };

    fetchUserDetails();
  }, [location]);

  return (
    <div className="min-h-screen bg-gray-100">
      <UserHeader />
      <div
        className="flex flex-col items-center justify-center pt-10 px-4 md:px-10 lg:px-12"
        style={{
          fontFamily: 'Montserrat, sans-serif',
          backgroundImage: `url(${img1})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          minHeight: 'calc(100vh - 64px)', // Adjust based on the header height
        }}
      >
        <h1
          className="text-4xl lg:text-5xl font-bold mb-4 text-black text-center"
          style={{ fontFamily: 'Montserrat, sans-serif' }}
        >
          Welcome To Injsur.AI!
        </h1>

        <h2
          className="text-2xl lg:text-3xl mb-6 text-black text-center max-w-3xl px-4"
          style={{ fontFamily: 'Montserrat, sans-serif' }}
        >
          Thank you for supporting our mission to change the game of fantasy sports and gambling with cutting edge predictive intelligence. We are thrilled to help you win your leagues, teams and more bets. We look forward to hearing your feedback and rolling out exciting new features in the near future.
        </h2>
        <Link to="/games">
          <button
            className="mt-10 mb-6 bg-transparent font-bold text-lg px-6 md:px-10 py-3 rounded-full hover:bg-white hover:text-black transition duration-200"
            style={{
              fontFamily: 'Montserrat, sans-serif',
              backgroundColor: '#d8bf82',
              color: 'black',
              borderColor: 'white',
            }}
            onMouseEnter={(e) =>
              (e.currentTarget.style.backgroundColor = '#a78c5d')
            }
            onMouseLeave={(e) =>
              (e.currentTarget.style.backgroundColor = '#d8bf82')
            }
          >
            Let's Go!
          </button>
        </Link>
      </div>
    </div>
  );
};

export default UserHomePage;
