// PaymentHandler.js

import React, { useState } from 'react';
import { Elements, useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { useNavigate } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import { CognitoIdentityProviderClient, InitiateAuthCommand } from '@aws-sdk/client-cognito-identity-provider';
import ReactGA from 'react-ga4';
import PhoneInput from 'react-phone-number-input/input';
import axios from 'axios';
import logo from '../images/injsurlogoGreen.png'; // Adjust the path based on your project structure

const getStripeKey = () => {
    const isLocalhost = window.location.href.includes('http://localhost:3000/');
    const isInjsur = window.location.href.includes('https://www.injsur.ai/');

    if (isLocalhost) {
        return 'pk_test_51PpgfUGrzdk3qn40VjV9BuM4On87glDerD3QFtirYH58uRk7yF59RvwpJQDP54VMLfJbldi8NbXl7j3f5ezCXkan00ZAawSzUZ'; // Replace with your test Stripe public key
    } else if (isInjsur) {
        return 'pk_live_51PpgfUGrzdk3qn40Ed5iDLfsdaxCtimHkhYUaub4kEhCvcgGRNychgbZwqbhZjvWorbp8hEZ8Bw10NMGFfxsHJuA00bz2sTt5w'; // Replace with your live Stripe public key
    }
    throw new Error('Invalid environment URL');
};

const stripePromise = loadStripe(getStripeKey());

const PaymentForm = () => {
    const navigate = useNavigate();
    const stripe = useStripe();
    const elements = useElements();

    const [formValues, setFormValues] = useState({
        // Signup fields
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        password: '',
        confirmPassword: '',
        city: '',
        state: '',
        zip: '',
        address: '',
        // Payment fields
        nameOnCard: '',
        billingAddress: '',
        billingZipCode: '',
        // Subscription type
        selectedSubscriptionType: 'monthly',
    });
    const [billingSameAsSignup, setBillingSameAsSignup] = useState(false);

    const [showPassword, setShowPassword] = useState(false);
    const [passwordRequirements, setPasswordRequirements] = useState({
        minLength: false,
        uppercase: false,
        lowercase: false,
        number: false,
        specialChar: false,
    });
    const [formError, setFormError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const currentDate = new Date().toISOString();

    const SUBSCRIPTION_COSTS = {
        monthly: 14.99,
        yearly: 79.99,
    };

    // Handle input changes for all form fields
    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormValues((prevValues) => ({
            ...prevValues,
            [name]: value,
        }));
    };

    const handlePhoneChange = (value) => {
        setFormValues((prevValues) => ({
            ...prevValues,
            phone: value,
        }));
    };

    const handlePasswordChange = (event) => {
        const password = event.target.value;
        setFormValues((prevValues) => ({
            ...prevValues,
            password,
        }));

        const requirements = {
            minLength: password.length >= 8,
            uppercase: /[A-Z]/.test(password),
            lowercase: /[a-z]/.test(password),
            number: /[0-9]/.test(password),
            specialChar: /[^A-Za-z0-9]/.test(password),
        };
        setPasswordRequirements(requirements);
    };

    // Function to check if user exists
    const checkUserExists = async (email) => {
        try {
            const response = await axios.post(
                'https://bb2b9p5bpk.execute-api.us-west-1.amazonaws.com/prod/checkUser',
                { email }
            );
            return response.data.exists;
        } catch (error) {
            console.error('Error checking user existence:', error);
            return false;
        }
    };

    // Function to navigate to verify
    const navigateToVerify = async (email) => {
        try {
            const client = new CognitoIdentityProviderClient({ region: 'us-west-1' });
            const params = {
                AuthFlow: 'CUSTOM_AUTH',
                ClientId: '18a9oeq65hd4t11jrd54fb5ln7', // Replace with your Cognito ClientId
                AuthParameters: {
                    USERNAME: email,
                },
            };
            const command = new InitiateAuthCommand(params);
            const response = await client.send(command);

            if (response.AuthenticationResult || response.ChallengeName === 'CUSTOM_CHALLENGE') {
                navigate(`/verify/${email}`, {
                    state: { email },
                });
            } else {
                console.error('Unexpected response during custom auth initiation:', response);
            }
        } catch (error) {
            console.error('Error initiating custom auth:', error);
        }
    };

    // Handle form submission
    const handleSubmit = async (event) => {
        event.preventDefault();
        setIsLoading(true);
        setFormError(null);

        // Validate password
        if (formValues.password !== formValues.confirmPassword) {
            setFormError('Passwords do not match');
            setIsLoading(false);
            return;
        }

        // Validate password requirements
        const isPasswordValid = Object.values(passwordRequirements).every(Boolean);
        if (!isPasswordValid) {
            setFormError('Password does not meet criteria');
            setIsLoading(false);
            return;
        }

        // Check if user already exists
        const userExists = await checkUserExists(formValues.email);
        if (userExists) {
            setFormError('An account with the given email already exists.');
            setIsLoading(false);
            return;
        }

        const {
            firstName,
            lastName,
            email,
            phone,
            password,
            city,
            state,
            zip,
            address,
            nameOnCard,
            billingAddress,
            billingZipCode,
            selectedSubscriptionType,
        } = formValues;

        // Prepare attributes for Cognito
        let attributes = {
            'custom:first_name': firstName,
            'custom:last_name': lastName,
            email: email,
            phone_number: phone,
            'custom:city': city,
            'custom:state': state,
            'custom:zip': zip,
            'custom:address': address,
            'custom:created_date': currentDate,
            'custom:updated_date': currentDate,
        };

        const subscriptionCost = SUBSCRIPTION_COSTS[selectedSubscriptionType];

        if (!stripe || !elements) {
            setIsLoading(false);
            return;
        }

        try {
            // Track events for analytics
            if (typeof ReactGA !== 'undefined') {
                ReactGA.event({
                    category: 'Payment',
                    action: 'Submit Payment',
                    label: selectedSubscriptionType,
                    value: subscriptionCost,
                });
            }
            if (typeof window.fbq !== 'undefined') {
                window.fbq('track', 'Purchase', {
                    value: subscriptionCost,
                    currency: 'USD',
                    subscriptionType: selectedSubscriptionType,
                });
            }

            // Create customer
            const customerResponse = await fetch(
                'https://bb2b9p5bpk.execute-api.us-west-1.amazonaws.com/prod/CreateCustomer',
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ email: email }),
                }
            );
            const customer = await customerResponse.json();
            let billingDetailsAddress;
            if (billingSameAsSignup) {
                billingDetailsAddress = {
                    line1: formValues.address,
                    city: formValues.city,
                    state: formValues.state,
                    postal_code: formValues.zip,
                };
            } else {
                billingDetailsAddress = {
                    line1: formValues.billingAddress,
                    postal_code: formValues.billingZipCode,
                };
            }
            // Create payment method
            const cardElement = elements.getElement(CardElement);
            const { paymentMethod, error } = await stripe.createPaymentMethod({
                type: 'card',
                card: cardElement,
                billing_details: {
                    email: email,
                    name: nameOnCard,
                    address: billingDetailsAddress,
                },
            });


            if (error) {
                console.log(error);
                setFormError(error.message);
                setIsLoading(false);
                return;
            }

            // Attach payment method
            await fetch(
                'https://bb2b9p5bpk.execute-api.us-west-1.amazonaws.com/prod/AttachPaymentMethod',
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        customer: customer.customerId,
                        paymentMethod: paymentMethod.id,
                    }),
                }
            );

            // Create subscription
            const subscriptionResponse = await fetch(
                'https://bb2b9p5bpk.execute-api.us-west-1.amazonaws.com/prod/createSeasonPassSubscription',
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        customer: customer.customerId,
                        subscriptionType: selectedSubscriptionType,
                        paymentMethodId: paymentMethod.id,
                    }),
                }
            );

            const subscription = await subscriptionResponse.json();

            if (!subscriptionResponse.ok) {
                throw new Error('Failed to create subscription');
            }

            // Update attributes with customer and subscription IDs before signing up
            const updatedAttributes = {
                ...attributes,
                'custom:customer_id': customer.customerId,
                'custom:subscription_id': subscription.subscriptionId,
            };

            // Sign up user with updated attributes including subscription ID
            await Auth.signUp({
                username: email,
                password: password,
                attributes: updatedAttributes,
            });

            // Trigger tracking pixel for ShareASale
            const trackingPixel = document.createElement('img');
            trackingPixel.src = `https://www.shareasale.com/sale.cfm?tracking=${customer.customerId}&amount=${subscriptionCost}&merchantID=161860&transtype=sale`;
            trackingPixel.width = 1;
            trackingPixel.height = 1;
            document.body.appendChild(trackingPixel);

            // Navigate to verify screen
            await navigateToVerify(email);
        } catch (err) {
            console.error(err);
            setFormError(err.message || 'An error occurred during signup and payment.');
        } finally {
            setIsLoading(false);
        }
    };


    return (

        <form onSubmit={handleSubmit}>
            {/* Signup Fields */}
            <h2 className="text-xl font-bold mb-4">Create New Account</h2>
            <div className="flex justify-between space-x-2">
                <input
                    type="text"
                    name="firstName"
                    placeholder="First Name"
                    value={formValues.firstName}
                    onChange={handleInputChange}
                    className="border p-2 mb-2 w-1/2 rounded"
                    required
                />
                <input
                    type="text"
                    name="lastName"
                    placeholder="Last Name"
                    value={formValues.lastName}
                    onChange={handleInputChange}
                    className="border p-2 mb-2 w-1/2 rounded"
                    required
                />
            </div>
            <div className="flex justify-between space-x-2">
                <input
                    type="text"
                    name="city"
                    placeholder="City"
                    value={formValues.city}
                    onChange={handleInputChange}
                    className="border p-2 mb-2 w-1/3 rounded"
                    required
                />
                <select
                    name="state"
                    value={formValues.state}
                    onChange={handleInputChange}
                    className="border p-2 mb-2 w-1/3 rounded text-gray-800"
                    required
                >
                    <option value="" disabled>Select State</option>
                    <option value="Alabama">Alabama</option>
                    <option value="Alaska">Alaska</option>
                    <option value="Arizona">Arizona</option>
                    <option value="Arkansas">Arkansas</option>
                    <option value="California">California</option>
                    <option value="Colorado">Colorado</option>
                    <option value="Connecticut">Connecticut</option>
                    <option value="Delaware">Delaware</option>
                    <option value="Florida">Florida</option>
                    <option value="Georgia">Georgia</option>
                    <option value="Hawaii">Hawaii</option>
                    <option value="Idaho">Idaho</option>
                    <option value="Illinois">Illinois</option>
                    <option value="Indiana">Indiana</option>
                    <option value="Iowa">Iowa</option>
                    <option value="Kansas">Kansas</option>
                    <option value="Kentucky">Kentucky</option>
                    <option value="Louisiana">Louisiana</option>
                    <option value="Maine">Maine</option>
                    <option value="Maryland">Maryland</option>
                    <option value="Massachusetts">Massachusetts</option>
                    <option value="Michigan">Michigan</option>
                    <option value="Minnesota">Minnesota</option>
                    <option value="Mississippi">Mississippi</option>
                    <option value="Missouri">Missouri</option>
                    <option value="Montana">Montana</option>
                    <option value="Nebraska">Nebraska</option>
                    <option value="Nevada">Nevada</option>
                    <option value="New Hampshire">New Hampshire</option>
                    <option value="New Jersey">New Jersey</option>
                    <option value="New Mexico">New Mexico</option>
                    <option value="New York">New York</option>
                    <option value="North Carolina">North Carolina</option>
                    <option value="North Dakota">North Dakota</option>
                    <option value="Ohio">Ohio</option>
                    <option value="Oklahoma">Oklahoma</option>
                    <option value="Oregon">Oregon</option>
                    <option value="Pennsylvania">Pennsylvania</option>
                    <option value="Rhode Island">Rhode Island</option>
                    <option value="South Carolina">South Carolina</option>
                    <option value="South Dakota">South Dakota</option>
                    <option value="Tennessee">Tennessee</option>
                    <option value="Texas">Texas</option>
                    <option value="Utah">Utah</option>
                    <option value="Vermont">Vermont</option>
                    <option value="Virginia">Virginia</option>
                    <option value="Washington">Washington</option>
                    <option value="West Virginia">West Virginia</option>
                    <option value="Wisconsin">Wisconsin</option>
                    <option value="Wyoming">Wyoming</option>
                </select>

                <input
                    type="text"
                    name="zip"
                    placeholder="Zip Code"
                    value={formValues.zip}
                    onChange={handleInputChange}
                    className="border p-2 mb-2 w-1/3 rounded"
                    required
                />
            </div>
            <input
                type="text"
                name="address"
                placeholder="Street Address"
                value={formValues.address}
                onChange={handleInputChange}
                className="border p-2 mb-2 w-full rounded"
                required
            />
            <input
                type="email"
                name="email"
                placeholder="Email"
                value={formValues.email}
                onChange={handleInputChange}
                className="border p-2 mb-2 w-full rounded"
                required
            />
            <PhoneInput
                name="phone"
                placeholder="Phone Number"
                value={formValues.phone}
                onChange={handlePhoneChange}
                className="border p-2 mb-2 w-full rounded"
                defaultCountry="US"
                required
            />

            <input
                type={showPassword ? 'text' : 'password'}
                name="password"
                placeholder="Password"
                value={formValues.password}
                onChange={handlePasswordChange}
                className="border p-2 mb-2 w-full rounded"
                required
            />
            <div className="mb-4">
                <label className="inline-flex items-center text-gray-700 text-sm">
                    <input
                        type="checkbox"
                        className="mr-2 leading-tight h-4 w-4"
                        checked={showPassword}
                        onChange={() => setShowPassword(!showPassword)}
                    />
                    <span className="text-md">Show Password</span>
                </label>
            </div>

            {/* Password criteria messages */}
            <p
                className={
                    passwordRequirements.minLength &&
                        passwordRequirements.uppercase &&
                        passwordRequirements.lowercase &&
                        passwordRequirements.number &&
                        passwordRequirements.specialChar
                        ? 'text-green-600'
                        : 'text-red-600'
                }
            >
                • At least 8 characters
            </p>
            <p className={passwordRequirements.uppercase ? 'text-green-600' : 'text-red-600'}>
                • At least one uppercase letter
            </p>
            <p className={passwordRequirements.lowercase ? 'text-green-600' : 'text-red-600'}>
                • At least one lowercase letter
            </p>
            <p className={passwordRequirements.number ? 'text-green-600' : 'text-red-600'}>
                • At least one number
            </p>
            <p className={passwordRequirements.specialChar ? 'text-green-600' : 'text-red-600'}>
                • At least one special character
            </p>
            <input
                type={showPassword ? 'text' : 'password'}
                name="confirmPassword"
                placeholder="Confirm Password"
                value={formValues.confirmPassword}
                onChange={handleInputChange}
                className="border p-2 my-2 w-full rounded"
                required
            />

            {/* Payment Fields */}
            <div className="mt-6">
                <h2 className="text-xl font-bold mb-4">Payment Details</h2>
                {/* Subscription Type Selection */}
                <div className="mb-4">
                    <label className="block text-sm font-medium text-gray-700">Choose Subscription Type:</label>
                    <div className="mt-1 flex gap-4">
                        {/* Monthly Subscription Box */}
                        <div
                            className={`cursor-pointer border p-3 rounded-md ${formValues.selectedSubscriptionType === 'monthly'
                                ? 'border-indigo-600 bg-indigo-50'
                                : 'border-gray-300'
                                }`}
                            onClick={() =>
                                setFormValues({ ...formValues, selectedSubscriptionType: 'monthly' })
                            }
                        >
                            <input
                                type="radio"
                                name="selectedSubscriptionType"
                                value="monthly"
                                checked={formValues.selectedSubscriptionType === 'monthly'}
                                onChange={() => { }}
                                className="form-radio text-indigo-600 hidden"
                            />
                            <div className="flex items-center">
                                <span className="ml-2 text-lg font-bold">Injsur Season Pass - $14.99</span>
                            </div>
                        </div>
                        {/* Yearly Subscription Box (if needed) */}
                        {/* Uncomment and adjust if you have yearly subscription */}
                    </div>
                </div>
                {/* <p>
                    Subscription Cost: $
                    {SUBSCRIPTION_COSTS[formValues.selectedSubscriptionType].toFixed(2)} (Expires on
                    February 10, 2025)
                </p> */}

                <hr className="my-2" style={{ borderTop: '1px solid black' }} />
                {/* <p>
                    Total Cost Due Now: $
                    // {SUBSCRIPTION_COSTS[formValues.selectedSubscriptionType].toFixed(2)}
                </p> */}

                {/* Card Details Section */}
                <div className="card-details" style={{ flex: '1' }}>
                    <h2 className="text-xl font-bold">Card Details</h2>
                    <div className="mb-4">
                        {/* Card input fields */}
                        <div className="mb-4">
                            <label className="block text-sm font-medium text-gray-700">Name on Card:</label>
                            <input
                                type="text"
                                name="nameOnCard"
                                value={formValues.nameOnCard}
                                onChange={handleInputChange}
                                className="mt-1 p-2 border rounded-md w-full"
                                required
                            />
                        </div>
                        <div className="mb-4">
                            <label className="inline-flex items-center text-gray-700 text-sm">
                                <input
                                    type="checkbox"
                                    className="mr-2 leading-tight h-4 w-4"
                                    checked={billingSameAsSignup}
                                    onChange={() => setBillingSameAsSignup(!billingSameAsSignup)}
                                />
                                <span className="text-md">Billing address same as above</span>
                            </label>
                        </div>

                        {!billingSameAsSignup && (
                            <>
                                <div className="mb-4">
                                    <label className="block text-sm font-medium text-gray-700">Billing Address:</label>
                                    <input
                                        type="text"
                                        name="billingAddress"
                                        value={formValues.billingAddress}
                                        onChange={handleInputChange}
                                        className="mt-1 p-2 border rounded-md w-full"
                                        required={!billingSameAsSignup}
                                    />
                                </div>
                                <div className="mb-4">
                                    <label className="block text-sm font-medium text-gray-700">Billing Zip Code:</label>
                                    <input
                                        type="text"
                                        name="billingZipCode"
                                        value={formValues.billingZipCode}
                                        onChange={handleInputChange}
                                        className="mt-1 p-2 border rounded-md w-full"
                                        required={!billingSameAsSignup}
                                    />
                                </div>
                            </>
                        )}

                        <div className="mb-4">
                            <label className="block text-sm font-medium text-gray-700">Card Details:</label>
                            <div className="mt-1 p-2 border rounded-md">
                                <CardElement options={{ hidePostalCode: true }} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Error Message Display */}
            {formError && <p className="text-red-600">{formError}</p>}
            {/* Terms and Privacy Policy Blurb */}
            <p className="text-sm text-gray-600 mb-4">
                By clicking Submit Payment, you agree to the Injsur.AI{' '}
                <a
                    href="https://www.injsur.ai/terms-of-use"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-blue-600 underline"
                >
                    Terms of Use
                </a>{' '}
                and{' '}
                <a
                    href="https://www.injsur.ai/privacy-policy"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-blue-600 underline"
                >
                    Privacy Policy
                </a>.
            </p>


            {/* Submit Button */}
            <button
                type="submit"
                disabled={isLoading}
                className={`bg-blue-500 text-white py-2 px-4 rounded w-full transition-colors duration-300 hover:bg-blue-600 ${isLoading ? 'opacity-70 cursor-not-allowed' : ''
                    }`}
            >
                {isLoading ? 'Processing...' : 'Submit Payment'}
            </button>
            <p className="mt-6 text-gray-600 text-center">
                Already have an account? <a href='/login' className="text-blue-500">Sign in</a>
            </p>
        </form>
    );
};

const PaymentHandler = () => {
    return (
        <div className="bg-gray-200 min-h-screen py-10">
            <div className="flex  justify-center items-center ">
                <img src={logo} alt="Logo" className="h-36 md:h-36" />
                <span className="absolute bottom-0 right-0 mb-4 text-[#fbf4e4] text-sm md:text-base font-bold">™</span>
            </div>
            <div className="mx-auto max-w-xl px-12 py-6 bg-white shadow-md rounded-lg">
                <Elements stripe={stripePromise}>
                    <PaymentForm />
                </Elements>
            </div>
        </div>
    );
};

export default PaymentHandler;
