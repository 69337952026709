const isLocalhost = typeof window !== 'undefined' && window.location.hostname === 'localhost';

const awsmobile = {
    aws_project_region: 'us-west-1',
    aws_cognito_identity_pool_id: 'us-west-1:6ceddf94-4444-4729-a8f7-a21820d9209d',
    aws_cognito_region: 'us-west-1',
    aws_user_pools_id: 'us-west-1_kmClJHtp4',
    aws_user_pools_web_client_id: '18a9oeq65hd4t11jrd54fb5ln7',
    oauth: {
        domain: 'us-west-1kmcljhtp4.auth.us-west-1.amazoncognito.com',
        scope: ['email', 'openid', 'profile'], // Combined scopes for Google and Facebook
        redirectSignIn: isLocalhost
            ? 'http://localhost:3000/home'
            : 'https://www.injsur.ai/home',
        redirectSignOut: isLocalhost
            ? 'http://localhost:3000/login'
            : 'https://www.injsur.ai/login',
        responseType: 'code',
    },
};

export default awsmobile;
