import React, { useState, useEffect, useRef } from 'react';
import { Navigate } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import { UseAuth } from './AuthContext';

const ProtectedRoute = ({ element }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(() => {
    const loggedIn = localStorage.getItem('loggedIn');
    return loggedIn === 'true' ? true : null;
  });
  
  const [attemptedAuth, setAttemptedAuth] = useState(false);
  
  const { token, isTokenExpired, refreshAccessToken } = UseAuth();

  const maxRetries = 5; // Maximum retry attempts
  const retryCount = useRef(0); // Use a ref so it won't reset on re-renders

  const checkAuth = async (isRetry = false) => {
    try {
      // Add a small delay on the very first attempt (not a retry)
      // This gives Amplify time to finish token exchange after redirect.
      if (!isRetry) {
        await new Promise((resolve) => setTimeout(resolve, 2000));
      }

      if (token && isTokenExpired(token)) {
        await refreshAccessToken();
      }

      await Auth.currentAuthenticatedUser();
      setIsAuthenticated(true);
      localStorage.setItem('loggedIn', 'true');
    } catch (error) {
      console.error('Authentication error:', error);
      // Don't set isAuthenticated to false here, let retries handle it.
    }
  };

  useEffect(() => {
    // If we have not determined authentication yet (isAuthenticated === null),
    // run the initial delayed check.
    if (isAuthenticated === null && !attemptedAuth) {
      const delay = setTimeout(() => {
        checkAuth(); // Initial attempt
      }, 5000); // Your original 5-second delay

      return () => clearTimeout(delay);
    }
  }, [token, isTokenExpired, refreshAccessToken, isAuthenticated, attemptedAuth]);

  useEffect(() => {
    // If after the initial attempt is done and isAuthenticated is still null,
    // we start the retry logic.
    if (isAuthenticated === null && !attemptedAuth) {
      const interval = setInterval(async () => {
        if (retryCount.current >= maxRetries || isAuthenticated !== null) {
          clearInterval(interval);
          // If we have finished all retries and isAuthenticated is still null,
          // now we mark that we have attempted final auth and failed.
          if (isAuthenticated === null) {
            setAttemptedAuth(true);
            setIsAuthenticated(false);
            localStorage.removeItem('loggedIn');
          }
          return;
        }
        retryCount.current++;
        await checkAuth(true); // isRetry = true
      }, 1000);

      return () => clearInterval(interval);
    }
  }, [isAuthenticated, attemptedAuth]);

  // If we still don't know if they're authenticated and haven't finished attempts, show Loading.
  if (isAuthenticated === null && !attemptedAuth) {
    return <div>Loading...</div>;
  }

  if (isAuthenticated) {
    // Clean up URL parameters after successful authentication
    const currentUrl = new URL(window.location.href);
    if (currentUrl.searchParams.has('code') || currentUrl.searchParams.has('state')) {
      currentUrl.searchParams.delete('code');
      currentUrl.searchParams.delete('state');
      window.history.replaceState({}, document.title, currentUrl.pathname);
    }
    return element;
  }

  // If not authenticated after all attempts, redirect to the login page
  return <Navigate to="/login" replace />;
};

export default ProtectedRoute;
