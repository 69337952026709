import React, { useState, useEffect } from 'react'
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from 'react-router-dom'
import { Amplify, Logger } from 'aws-amplify';
import { AuthProvider } from './components/AuthContext'
import TokenTimeout from './components/TokenTimeout'
import ProtectedRoute from './components/ProtectedRoute'
import Home from './components/Home'
import Login from './components/Login'
import Signup from './components/Signup'
import SignUpTermsOfService from './components/SignUpTermsOfService'
import UserHome from './components/UserHome'
import PrivacyPolicy from './components/PrivacyPolicy'
import TermsOfUse from './components/TermsOfUse'
import GetInsights from './components/GetInsights'
import Frailty from './components/Frailty'
import AboutUs from './components/AboutUs'
import LearnMore from './components/LearnMore'
import PaymentHandler from './components/SubscriptionPayment'
import VerifyCode from './components/VerifyCode'
import { UserProvider } from './components/UserContext.js'
import awsmobile from './aws-exports'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import FrailtyGames from './components/FrailtyGames.js'
import Teams from './components/Teams.js'
import FrailtyQB from './components/FrailtyQB.js'
import FrailtyRB from './components/FrailtyRB.js'
import FrailtyWR from './components/FrailtyWR.js'
import FrailtyTE from './components/FrailtyTE.js'
import { FrailtyModelProvider } from './components/FrailtyModelContext'
import Games from './components/Games.js'
import UserAccount from './components/UserAccount.js'
import UpdatePaymentMethod from './components/UpdatePaymentMethod.js'
import ScrollToTop from './components/ScrollTop'
import FAQ from './components/FAQ.js'
import ResetPassword from './components/PasswordReset.js'
import ReactGA from 'react-ga4'
import SignupAndPayment from './components/SignupAndPayment.js'
import SignUpFree from './components/SignUpFree.js'
// Removed: import { Helmet } from 'react-helmet'

Amplify.configure(awsmobile)

function AppContent() {
  const location = useLocation()

  useEffect(() => {
    ReactGA.send({ hitType: 'pageview', page: location.pathname }) // Track route changes
  }, [location])

  return (
    <>
      {/* Removed Helmet component and noscript tags */}
      <ScrollToTop />
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/about" element={<AboutUs />} />
        <Route exact path="/learn-more" element={<LearnMore />} />
        <Route exact path="/faq" element={<FAQ />} />
        <Route exact path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route exact path="/terms-of-use" element={<TermsOfUse />} />
        

      </Routes>

      <AuthProvider>
        <UserProvider>
          <div>
            <Routes>
              <Route path="/login" element={<Login />} />
              <Route
                exact
                path="/reset-password"
                element={<ResetPassword />}
              />
              <Route path="/sign-up" element={<SignUpFree />} />
              <Route
                path="/sign-up-terms-of-service"
                element={<SignUpTermsOfService />}
              />
              <Route
                path="/sign-up-payment"
                element={<PaymentHandler />}
              />
              <Route
                path="/verify/:username"
                element={<VerifyCode />}
              />
              <Route
                path="/home"
                element={<ProtectedRoute element={<UserHome />} />}
              />

              <Route
                path="/get-insights"
                element={
                  <ProtectedRoute
                    path="/get-insights"
                    element={<Frailty />}
                  />
                }
              />
              <Route
                path="/account"
                element={
                  <ProtectedRoute
                    path="/account"
                    element={<UserAccount />}
                  />
                }
              />
              <Route
                path="/update-payment-method"
                element={
                  <ProtectedRoute
                    path="/update-payment-method"
                    element={<UpdatePaymentMethod />}
                  />
                }
              />

              {/* Wrap only the frailty-related routes with the FrailtyModelProvider */}
              <Route
                path="*"
                element={
                  <FrailtyModelProvider>
                    <Routes>
                      <Route
                        path="/games"
                        element={
                          <ProtectedRoute
                            path="/games"
                            element={<Games />}
                          />
                        }
                      />
                      <Route
                        path="/teams"
                        element={
                          <ProtectedRoute
                            path="/teams"
                            element={<Teams />}
                          />
                        }
                      />
                      <Route
                        path="/qb"
                        element={
                          <ProtectedRoute
                            path="/qb"
                            element={<FrailtyQB />}
                          />
                        }
                      />
                      <Route
                        path="/rb"
                        element={
                          <ProtectedRoute
                            path="/rb"
                            element={<FrailtyRB />}
                          />
                        }
                      />
                      <Route
                        path="/wr"
                        element={
                          <ProtectedRoute
                            path="/wr"
                            element={<FrailtyWR />}
                          />
                        }
                      />
                      <Route
                        path="/te"
                        element={
                          <ProtectedRoute
                            path="/te"
                            element={<FrailtyTE />}
                          />
                        }
                      />
                    </Routes>
                  </FrailtyModelProvider>
                }
              />
            </Routes>
            <ToastContainer />
          </div>
          <TokenTimeout />
        </UserProvider>
      </AuthProvider>
    </>
  )
}

function App() {
  const [isTempAuthenticated, setIsTempAuthenticated] = useState(false)
  const hardcodedUUID = '123e4567-e89b-12d3-a456-426614174000'

  useEffect(() => {
    ReactGA.initialize('G-VKLGZVR8P9', { debugMode: true }) // Initialize only once
    ReactGA.send('pageview') // Initial pageview
  }, [])

  return (
    <Router>
      <AppContent />
    </Router>
  )
}

export default App
